// Mark: Used in V2
//TODO rework this to use v2 components and colors
import classNames from "classnames"
import React, { useEffect } from "react"

import CloseIcon from "./V2/CloseIcon"
import { storyblokHorizontalGutterClasses } from "../../constants/spacing"
import { Color } from "../../constants/V2/color"

type Props = {
  open: boolean
  handleClose: () => void
  explicitWidth?: number
  closeIconOutside?: boolean
  closeIconColor?: string //TODO rework this to accept v2 colors
  children: React.ReactNode
}

const Modal = ({
  open,
  handleClose,
  explicitWidth,
  closeIconOutside = true,
  children,
}: Props) => {
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")
    if (open) {
      bodyElement[0].style.overflow = "hidden"
    } else {
      bodyElement[0].style.overflow = "scroll"
    }
  }, [open])

  return (
    <div
      className={classNames(
        storyblokHorizontalGutterClasses,
        "fixed left-0 top-0 z-50 flex h-screen w-full overflow-y-auto py-80-v2 transition-all duration-300",
        open ? "opacity-100" : "pointer-events-none opacity-0"
      )}
      data-test-id="global-modal"
    >
      <div
        className="relative z-10 m-auto max-w-full"
        style={{ width: explicitWidth ? `${explicitWidth}px` : "80vw" }}
      >
        {children}
        <div
          className={classNames(
            "absolute h-[28px] w-[28px] cursor-pointer transition-all duration-300 hover:opacity-70",
            closeIconOutside
              ? "-top-40-v2 right-0"
              : "right-8-v2 top-8-v2 sm-v2:right-20-v2 sm-v2:top-20-v2"
          )}
          onClick={handleClose}
          data-test-id="modal-close-button"
        >
          <CloseIcon
            width="24px"
            height="24px"
            hideBorder
            strokeWidth="2"
            strokeColor={Color.White}
          />
        </div>
      </div>
      <div
        className="fixed left-0 top-0 h-full w-full bg-charcoal/80"
        onClick={handleClose}
      />
    </div>
  )
}

export default Modal
